<script>
  import FooterContent from '../content/FooterContent.svelte';
</script>

<footer class="footer p-10 bg-neutral text-neutral-content">
  <FooterContent />
</footer>

<style>

</style>
