<script>
  function comingSoon() {
    alert('Coming Soon');
  }
</script>

<div>
  <span class="footer-title">Software</span>
  <a class="link link-hover" href="/" on:click|preventDefault={comingSoon}
    >Corporate</a
  >
  <a class="link link-hover" href="/" on:click|preventDefault={comingSoon}
    >Government</a
  >
  <a class="link link-hover" href="/" on:click|preventDefault={comingSoon}
    >SaaS</a
  >
  <a class="link link-hover" href="/" on:click|preventDefault={comingSoon}
    >Startup</a
  >
</div>
<div>
  <span class="footer-title">Social</span>
  <a
    class="link link-hover"
    target="_blank"
    href="https://www.crunchbase.com/organization/brilliance-labs"
  >
    Crunchbase
  </a>
  <a
    class="link link-hover"
    target="_blank"
    href="https://github.com/brilliancelabs"
  >
    GitHub
  </a>
  <a
    class="link link-hover"
    target="_blank"
    href="https://www.linkedin.com/company/5714360"
  >
    LinkedIn
  </a>
  <a
    class="link link-hover"
    target="_blank"
    href="https://twitter.com/brilliancelabs"
  >
    Twitter
  </a>
</div>
<div>
  <span class="footer-title">Legal</span>
  <a class="link link-hover" href="/" on:click|preventDefault={comingSoon}
    >Terms of use</a
  >
  <a class="link link-hover" href="/" on:click|preventDefault={comingSoon}
    >Privacy policy</a
  >
  <a class="link link-hover" href="/" on:click|preventDefault={comingSoon}
    >Cookie policy</a
  >
</div>
<div>
  <span class="footer-title">Raise Platform RSVP</span> 
  <div class="form-control w-80">
    <div class="relative">
      <form method="post" action="https://wf.logi.cloud/frosty-poitras-loving-lederberg-trusting-noyce-laughing-payne">
        <input type="hidden" name="wf-success-url" value="https://www.webforma.io?raise=true" />
        <input type="text" name="email" placeholder="Your Email" required class="input caret-black input-bordered w-full pr-16" /> 
        <button class="btn btn-primary absolute top-0 right-0 rounded-l-none">
          RSVP
        </button>
      </form>
    </div>
  </div>
</div>
