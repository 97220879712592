<script>
  import axios from 'axios';
  import Modal from '../components/Modal.svelte';

  const defaultMessage = '😍😍😍';
  let submitted = false;

  $: form = {
    message: defaultMessage,
  };
  function cleanDefault() {
    if (form.message === defaultMessage) {
      form.message = '';
    }
  }
  function restoreDefault() {
    if (form.message === '') {
      form.message = defaultMessage;
    }
  }
  async function handleSubmit() {
    const url = 'https://wf.logi.cloud/tender-heisenberg-inspiring-aryabhata-cranky-curie-intelligent-northcutt';
    try {
      await axios.post(url, form);
      submitted = true;
      form = { message: defaultMessage };
    } catch (error) {
      console.info('submit error', error);
    }
  }
</script>

{#if submitted}
  <Modal />
{/if}

<section class="flex flex-col max-w-4xl mx-auto prose lg:prose-xl">
  <!-- svelte-ignore a11y-missing-content -->
  <a id="contact" class="flex anchor" href="#contact"></a>
  <h1 class="text-left">Contact Us</h1>
  <form on:submit|preventDefault={handleSubmit}>
    <div class="bg-slate-100 rounded-xl prose lg:prose-xl shadow-lg">
      <div class="card-body">
        <div class="form-control">
          <label class="label" for="name">
            <span class="label-text">Your Name</span>
          </label>
          <input
            bind:value={form.name}
            type="text"
            name="name"
            required
            placeholder="Your Name"
            class="input input-bordered"
          />
        </div>
        <div class="form-control">
          <label class="label" for="email">
            <span class="label-text">Email</span>
          </label>
          <input
            bind:value={form.email}
            name="email"
            type="email"
            required
            placeholder="Your Email"
            class="input input-bordered"
          />
        </div>
        <div class="form-control">
          <label class="label" for="message">
            <span class="label-text">Your Message</span>
          </label>
          <textarea
            name="message"
            on:blur={restoreDefault}
            on:focus={cleanDefault}
            bind:value={form.message}
            class="textarea textarea-bordered textarea-lg w-full"
          />
        </div>
        <div class="form-control mt-6">
          <button class="btn btn-primary" type="submit">Send</button>
        </div>
      </div>
    </div>
  </form>

</section>
