<script>
  import { createEventDispatcher } from 'svelte';

  export let href;
  export let page = '';

  let className = '';
  export { className as class };

  $: resolvedHref = href ? href : `#${page}`;

  const dispatch = createEventDispatcher();
  function triggerUpdatePage() {
    dispatch('pageUpdated', page);
  }
</script>

{#if page}
  <a href="{resolvedHref}" class="{className}" on:click={triggerUpdatePage}>
    <slot />
  </a>
{/if}
