<script>
  import Navbar from './components/Navbar.svelte';
  import Contact from './pages/Contact.svelte';
  import Home from './pages/Home.svelte';
  import About from './pages/About.svelte';
  import Footer from './components/Footer.svelte';

  const mobileMax = 600;
  let page = 'home';
  let screenSize = 0;
  let showHome = true;
  let showAbout = true;
  let showContact = true;

  $: useMobile = screenSize <= mobileMax;

  function updatePage(ev) {
    page = ev.detail;
    if (useMobile) {
      showHome = page === 'home';
      showAbout = page === 'about';
      showContact = page === 'contact';
    }
  }
</script>

<svelte:window bind:innerWidth={screenSize} />

<Navbar on:pageUpdated={updatePage} />
<main class="container mx-auto px-4 content-center">
  <Home />
  <About />
  <Contact />
</main>

<section class="bottom" />
<Footer />

<style>
  section.bottom {
    padding: 2rem 0;
  }
</style>
