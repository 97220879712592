<script>
  import Pico from '@ui/pico/pico.svelte';

  let open = true;
  setTimeout(() => {
    close();
  }, 3000);

  function close() {
    open = false;
  }
</script>

{#if open}
<Pico />
{/if}

<dialog open={open} on:click={close} on:keydown={null}>
  <article>
    <hgroup>
      <h2>Thank you!</h2>
      <h4>Your feedback is appreciated.</h4>
    </hgroup>
  </article>
</dialog>
