<section class="flex flex-col max-w-4xl mx-auto prose lg:prose-xl">
  <h1 class="text-left">Software Releases & Development Labs</h1>
  <p>
    For more than a decade Brilliance Labs (BL) has offered sofware development
    services on a project basis or via staff augmentation. Now BL offers,
    DevLabs, which are the best of both worlds. DevLabs may be scaled to augment
    corporate and startup staff or utilized as a complete dev solution to
    deliver a new sofware application or service.
  </p>
  <p>
    <strong>BL&gt;</strong> may be veterans in the corporate development space but
    we are just beginning our journey as creators of applications, packages, and
    software-as-a-service releases. Stay tuned to partake in our frequent release
    schedule of software that we feel very good about. Software that will improve
    productivity, communication, and joy for our end-users.
  </p>
</section>
