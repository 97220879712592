<script>
  import { ensign, fullName } from '../config';
  import PageLink from './PageLink.svelte';

  const img = '/img/photo-1506043718651-8ff9124fd9e4.avif';
</script>

<div class="hero min-h-screen" style="background-image: url({img});">
  <div class="hero-overlay bg-opacity-60" />
  <div class="hero-content text-center text-neutral-content">
    <div class="max-w-md">
      <h1 class="mb-5 text-5xl font-bold">
        {fullName}
      </h1>
      <p class="mb-5">
        {@html ensign} is a full-service software firm that releases SaaS Software,
        provides software development labs startup, corporate, and goverment clients.
      </p>
      <button class="btn btn-primary">
        <PageLink page="contact">Contact Us</PageLink>
      </button>
    </div>
  </div>
</div>

<style>
  .hero {
    min-height: 50vh;
  }
</style>
