<script>
  import Ensign from './Ensign.svelte';
  import PageLink from './PageLink.svelte';
</script>

<div class="navbar flex bg-base-100 fixed z-10">
  <nav>
    <div class="flex-1">
      <a href="/" class="btn btn-ghost normal-case text-xl">
        <Ensign />
        <span class="hidden md:flex">Brilliance Labs</span>
      </a>
    </div>
    <div class="flex-none">
      <ul class="menu menu-horizontal px-1">
        <li>
          <PageLink page="home" on:pageUpdated>Home</PageLink>
        </li>
        <li>
          <PageLink page="about" on:pageUpdated>About</PageLink>
        </li>
        <li>
          <PageLink page="contact" on:pageUpdated>Contact</PageLink>
        </li>
      </ul>
    </div>
  </nav>
</div>

<style>
  nav {
    margin: auto;
    min-width: 66vw;
  }
</style>
